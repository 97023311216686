<template>
  <div v-if="!sellerRequestStore.loader" class="details">
    <div class="details__header">
      <CarMinInfo
        v-if="sellerRequestStore.requestItem !== null"
        :brand="sellerRequestStore.requestItem.car_brand.name"
        :model="sellerRequestStore.requestItem.car_model.name"
        :year="sellerRequestStore.requestItem.year"
        :engine="sellerRequestStore.requestItem?.engine_type.value"
        :transmission="sellerRequestStore.requestItem.transmission.value"
        :capacity="sellerRequestStore.requestItem.engine_capacity"
        class="mr-15"
      />
      <div>
        <div class="details__detail">VIN-код</div>
        <div class="details__text mt-1">
          {{ sellerRequestStore.requestItem.vin || "-" }}
        </div>
      </div>
    </div>

    <div class="details__row">
      <div class="details__detail">Назва запчастини</div>
      <div class="details__text">
        {{ sellerRequestStore.requestItem.part_category.name }}
      </div>
    </div>

    <div class="details__row">
      <div class="details__detail">Група запчастин</div>
      <div class="details__text">
        {{ sellerRequestStore.requestItem.part_category?.parent.name || "-" }}
      </div>
    </div>

    <div class="details__row">
      <div class="details__detail">Опис запчастини</div>
      <div class="details__text">
        {{ sellerRequestStore.requestItem.part_description || "-" }}
      </div>
    </div>

    <div class="details__row">
      <div class="details__detail">Код запчастини</div>
      <div class="details__text">
        {{ sellerRequestStore.requestItem.part_code || "-" }}
      </div>
    </div>

    <div class="details__row">
      <div class="details__detail">Стан</div>
      <div class="details__text">
        {{ sellerRequestStore.requestItem.part_condition.label }}
      </div>
    </div>

    <div class="details__row">
      <div class="details__detail">Тип</div>
      <div class="details__text">
        {{ sellerRequestStore.requestItem.part_type.label }}
      </div>
    </div>

    <div class="details__row">
      <div class="details__detail">Бажана ціна</div>
      <div class="details__text">
        {{ sellerRequestStore.requestItem.recommended_price }} грн
      </div>
    </div>

    <div class="details__row">
      <div class="details__detail">Коментар до замовлення</div>
    </div>

    <div class="details__text" style="word-wrap: break-word">
      {{ sellerRequestStore.requestItem.comment || "-" }}
    </div>
    <div
      v-if="
        sellerRequestStore.requestItem.status ===
          SellerRequestStatus.CONFIRMED &&
        userStore.user.id === sellerRequestStore.requestItem.customer.id
      "
      class="details__row"
    >
      <v-btn
        @click="cancelOffer"
        size="large"
        color="red"
        block
        variant="outlined"
      >
        Скасувати заявку
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSellerRequestStore } from "@/stores/useSellerRequestStore";
import { useCustomersStore } from "@/stores/useCustomersStore";
import { SellerRequestStatus } from "@/models/sellerRequests";

const props = defineProps({
  id: {
    type: Number,
    requried: true,
  },
});
const userStore = useUserStore();
const sellerRequestStore = useSellerRequestStore();
const customersStore = useCustomersStore();

const cancelOffer = () => {
  customersStore.cancelOffer(sellerRequestStore.requestItem.id);
};

useAsyncData("requests-list", () =>
  sellerRequestStore.getSellerRequestItem(props.id)
);
</script>

<style scoped lang="scss">
.details {
  &__detail {
    color: var(--color-primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.014px;
    min-width: 240px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  &__text {
    color: var(--color-primary-black);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 24px;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 12px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 24px;

      margin-bottom: 24px;
    }
  }
}
</style>
